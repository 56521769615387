<template>
  <div
    class="bet"
    :class="{
      deleteAnimation: isRemoved,
      scale: isBetAppeared,
      swipedLeftAnimation: isSwipedLeft,
      swipedRightAnimation: isSwipedRight,
      wideScreenLayout: isWideScreenLayout
    }"
    ref="bet"
    v-horizontalSwipe="swipeHandlers"
  >
    <div :class="{ betOverlay: isBetAppeared }" />
    <div class="overlayLock" v-if="bet.locked">
      <i class="icon icon-lock" />
    </div>
    <div class="betHeader">
      <div class="betOutcome">
        {{ bet.market }}
      </div>
      <i class="icon icon-close" @click="handleRemoveBet(bet.id)"></i>
    </div >
    <div class="betBody">
      {{ bet.outcome }}
    </div>
    <div class="betFooter">
      <div class="stakeAndRound">
        <span class="betFooterItem">
          <div>
            {{ translations.general_round }} {{ bet.roundNumber }} {{ futureRoundsText(bet) }}
          </div>
          <div class="betOdds">
            {{ isOddDefined ? oddsConverter(bet.odds) : null }}
          </div>
        </span>
        <BetslipInput v-if="isPaymentBetAllowed"
          @change="handleBetPayment($event, bet.id, bet.combinations)"
          @fieldFocus="setActiveBetInput(bet.id)"
          :parentValue="bet.stake"
          :label="translations.general_stake"
          :isValid="bet.isValid"
          @delete="handleBetPaymentDelete(bet.id, bet.combinations)"
          class="paymentInput"
        />
        <span v-else class="betFooterItem betFooterStake">
          <span>{{ translations.general_stake }}</span>
          <span class="betStake">{{ bet.stake.toFixed(2) }}</span>
        </span>
      </div>
      <div
        class="perBetFutureRounds"
        :style="{ display: isFuturePerBetAllowed ? 'flex' : 'none' }">
        <Select
          @change="handleBetFutureRounds($event, bet.id)"
          :label="translations.general_future_bet"
          :orientation="isTopOrientation ? 'top' : 'bottom'"
          :value="bet.numEvents"
          :options="getMaxPerBetFutureRounds(bet.maxPerBetFutureRounds)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { range, isNil } from 'lodash';
import BetslipInput from '../../BetslipArea/GamesBetslipInput';
import Select from '../../Select/Select';
import horizontalSwipe from '../../../directives/horizontalSwipe';
import oddConverter from '../../../utility/oddConverter';

export default {
  name: 'BetslipBetSingle',
  components: {
    BetslipInput,
    Select,
  },
  data() {
    return {
      isBetAppeared: false,
      isRemoved: false,
      isSwipedRight: false,
      isSwipedLeft: false,
      removeTimeoutId: 0,
      betAppeardTimeoutID: 0,
      stakeBoxTimeoutId: 0,
      skipBetAppearAnimation: false,
      swipeHandlers: {
        left: this.handleSwipeLeft,
        right: this.handleSwipeRight,
      },
    };
  },
  props: {
    bet: {
      type: Object,
    },
    isLastBet: {
      type: Boolean,
    },
  },
  directives: {
    horizontalSwipe,
  },
  computed: {
    ...mapGetters('gamesBetslip', [
      'config',
      'futureRounds',
      'isBetAppearAnimationDisabled',
      'numberOfBets',
      'minBetPayment',
    ]),
    ...mapGetters(['translations', 'isDesktop', 'isMobile', 'oddType']),
    isFuturePerBetAllowed() {
      return this.config.isFuturePerBetAllowed;
    },
    isPaymentBetAllowed() {
      return this.config.isPaymentBetAllowed;
    },
    isOddDefined() {
      return !isNil(this.bet.odds);
    },
    isWideScreenLayout() {
      return !this.isMobile;
    },
    isTopOrientation() {
      return this.numberOfBets > 2 && this.isLastBet;
    },
  },
  methods: {
    ...mapActions('gamesBetslip', [
      'betPaymentDelete',
      'removeBet',
      'setBetPayment',
      'setFutureRoundsPerBet',
      'setActiveBetslipInput',
      'setIsStakeBoxVisible',
      'setIsBetAppearAnimationDisabled',
    ]),
    futureRoundsText(bet) {
      if (this.futureRounds > 1 && bet.numEvents > 1) {
        return ` - ${bet.roundNumber + bet.numEvents - 1}`;
      }

      if (bet.numEvents > 1) {
        return ` - ${bet.roundNumber + bet.numEvents - 1}`;
      }

      return '';
    },
    getMaxPerBetFutureRounds(max) {
      return range(1, max + 1);
    },
    handleBetPayment(betStake, betId, combinations) {
      if (Number(this.bet.stake) === Number(betStake)) return;

      this.setBetPayment({ betStake, betId, combinations });
    },
    handleBetFutureRounds(futureRounds, betId) {
      this.setFutureRoundsPerBet({ futureRounds, betId });
    },
    handleBetPaymentDelete(betId, combinations) {
      this.betPaymentDelete({ betId, combinations });
    },
    handleRemoveBet(betId) {
      this.isRemoved = true;

      if (this.removeTimeoutId) clearTimeout(this.removeTimeoutId);

      this.removeTimeoutId = setTimeout(() => {
        this.removeBet(betId);
        this.isRemoved = false;
      }, 250);
    },
    openStakeBox() {
      if (this.stakeBoxTimeoutId) clearTimeout(this.stakeBoxTimeoutId);

      this.stakeBoxTimeoutId = setTimeout(() => {
        this.setIsStakeBoxVisible(true);
      }, 200);
    },
    handleSwipeLeft() {
      this.removeBetLeft();
    },
    handleSwipeRight() {
      this.removeBetRight();
    },
    removeBetLeft() {
      if (!this.isDesktop) {
        this.isSwipedLeft = true;
        setTimeout(() => {
          this.removeBet(this.bet.id);
          this.isSwipedLeft = false;
        }, 250);
      }
    },
    removeBetRight() {
      if (!this.isDesktop) {
        this.isSwipedRight = true;
        setTimeout(() => {
          this.removeBet(this.bet.id);
          this.isSwipedRight = false;
        }, 250);
      }
    },
    setActiveBetInput(betId) {
      this.setActiveBetslipInput(betId);
      this.openStakeBox();
    },
    oddsConverter(odd) {
      return oddConverter(odd, this.oddType);
    },
  },
  mounted() {
    if (!this.isBetAppearAnimationDisabled) {
      this.isBetAppeared = true;

      this.$refs.bet.scrollIntoView({ behavior: 'smooth', block: 'end' });

      this.betAppeardTimeoutID = setTimeout(() => {
        this.isBetAppeared = false;
      }, 600);
    }

    this.$nextTick(() => {
      this.setIsBetAppearAnimationDisabled(false);
    });
  },
  // Vue 2 vs Vue3 issue => beforeDestroy hook doesn't exist in vue3 it's changed to beforeUnmount
  beforeUnmount() {
    clearTimeout(this.betAppeardTimeoutID);
    clearTimeout(this.removeTimeoutId);
    clearTimeout(this.stakeBoxTimeoutId);
  },
  beforeDestroy() {
    clearTimeout(this.betAppeardTimeoutID);
    clearTimeout(this.removeTimeoutId);
    clearTimeout(this.stakeBoxTimeoutId);
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/animations';

.bet {
  min-height: 102px;
  max-height: 148px;
  padding: 8px 8px;
  position: relative;
  background-color: var(--card);
  touch-action: pan-y !important;

  &.scale {
    animation-name: scale;
    animation-duration: 0.6s;
    transition-timing-function: ease;
  }

  &.swipedRightAnimation {
    position: relative;
    animation-name: swipedRight;
    animation-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  &.swipedLeftAnimation {
    position: relative;
    animation-name: swipedLeft;
    animation-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  .betOverlay {
    animation-name: betAppear;
    animation-duration: 0.6s;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    transition-timing-function: ease;
  }

  .overlayLock {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlay-lock);
    z-index: 1;

    .icon-lock {
      font-size: 36px;
      color: var(--text-primary-2);
    }
  }

  .betHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;

    .icon-close {
      cursor: pointer;
      font-size: 20px;
      color: var(--text-primary-2);
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 2;
    }

    .betOutcome {
      color: var(--secondary);
      line-height: 18px;
    }
  }

  .betFooter {
      .stakeAndRound {
        display: flex;

        .betOdds {
          font-weight: 700;
        }

        margin-top: 8px;

        .betFooterItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 400;
          background-color: var(--card-section);
          padding: 11px 8px;
          width: 100%;
          height: 40px;
          border-radius: 2px;
          margin-right: 1px;

          &:last-child {
            margin-right: 0px;
            min-width: auto;
          }

          &.betFooterStake {
            justify-content: space-between;

            .betStake {
              font-weight: 700;
            }
          }
        }

        .betStake {
          font-weight: 700;
        }

        ::v-deep .betslip-input-wrapper {
            width: 103px;

            input {
              width: 103px;
            }
        }
      }

      .perBetFutureRounds {
        display: flex;
        justify-content: flex-end;
        margin-top: 8px;

        ::v-deep .select  {
          width: auto;
          height: auto;
          line-height: unset;

          .items {
            line-height: 40px;
            max-height: 100px;
            z-index: 100;

            &.top {
              bottom: 22px;
            }

            &.bottom {
              top: 22px;
            }
          }

          .select-input {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            font-size: 12px;

            .label {
              position: static;
              margin-right: 4px;
            }

            .value {
              position: static;
              margin-right: 4px;
            }

            .icon {
              position: static;
            }
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0px;
    }

  .betBody {
    font-weight: 700;
    line-height: 18px;
  }
}

.deleteAnimation {
  animation-name: delete;
  animation-duration: 0.3s;
  transition-timing-function: ease-out;
}


.bet {
  &.wideScreenLayout {
    .betFooter {
      .stakeAndRound {
        ::v-deep .betslip-input-wrapper {
          width: 100px;

          input {
            width: 100px;
          }
        }
      }
    }
  }
}
</style>
